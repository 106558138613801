<template>
  <a-config-provider :theme="Theme">
    <a-select class="df-select" v-bind="$attrs" :size="size">
      <template v-for="slotName in Object.keys($slots)" #[slotName]>
        <slot :name="slotName"></slot>
      </template>
    </a-select>
  </a-config-provider>
</template>
<script setup lang="ts">
import type { SizeType } from 'ant-design-vue/es/config-provider';
defineOptions({
  inheritAttrs: false,
});
type PropType = {
  size?: SizeType;
};
withDefaults(defineProps<PropType>(), {
  size: 'large',
});
const ItemTheme = {
  // 多选item
  colorFillSecondary: '#ffffff', // 背景色
  colorSplit: 'rgba(25, 25, 25, 0.1)', // 边框色
};
const Theme = {
  components: {
    Select: {
      colorText: '#09090b',
      lineWidth: 1.5,
      colorBgContainer: '#F0F0F5',
      controlHeightLG: 40,
      controlHeight: 32,
      controlHeightSM: 24,
      fontSizeLG: 14,
      fontSize: 12,
      colorBorder: 'transparent',
      colorPrimaryHover: '#191919',
      colorTextPlaceholder: '#9e9e9e',
      controlOutlineWidth: 0,
      colorTextQuaternary: '#191919', // 下拉icon颜色
      colorIcon: '#9E9E9E',
      colorIconHover: '#1A1A1A',
      colorBgContainerDisabled: '#F0F1F5', // 禁用背景色
      controlItemBgHover: '#F5F7FB',
      ...ItemTheme,
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.ant-select-selector) {
  &:focus {
    border-color: #1a1a1a;
    box-shadow: none;
    background-color: #ffffff;
  }
  .ant-select-selection-placeholder {
    margin-left: 1px;
  }
}
.ant-select-disabled {
  opacity: 0.4;
}
.df-select {
  &.ant-select-focused {
    :deep(.ant-select-selector) {
      background-color: #ffffff;
    }
  }
}
.df-select {
  :deep(.ant-select-selector:hover) {
    background-color: #e8e8ef;
  }
  &:hover:not(.ant-select-focused) {
    :deep(.ant-select-selector) {
      border-color: transparent !important;
    }
  }
}
</style>
